<template>
  <div class="bmap" id="container" :zoom="zoom"></div>
  <!-- 边框样式 -->
  <div class="BianKuanClass">
    <div class="TopTitle">湘江古镇群</div>
  </div>
  <!-- <div class="BottomNavBox" @mouseleave="YiChuBottomNav">
        <div class="ModuleNavBox" v-for="(item, index) in ModuleBoxList" :key="index" @click="ButtomNavTab(index)" @mouseenter="YiRuBottomNav(index)">
            <div v-if="active==index">{{ item.name }}</div>
            <img v-if="active!=index" :src="item.img" style="margin-left: 1vh;border-radius: 13px;">
        </div>
    </div> -->
  <!-- 底部导航 -->
  <div class="BottomNavBox" @mouseleave.native="YiChuBottomNav()">
    <div
      class="ModuleNavBox"
      v-for="(ButtomNav, index) in ButtomNav"
      :key="index"
      @mouseenter.native="YiRuBottomNav(index)"
      :class="{ actived: active == index }"
      @click="ButtomNavTab(index)"
    >
      <div>{{ ButtomNav }}</div>
    </div>
  </div>
  <!-- 左部导航 -->
  <div class="ModuleBox">
    <div class="Module">
      <div
        class="ModuleLiTwo"
        v-for="(ModuleBoxList, index) in ModuleBoxList"
        :key="index"
        :style="{ backgroundImage: 'url(' + ModuleBoxList.img + ')' }"
        :class="{ ModuleBoxactiveactived: ModuleBoxactive == index }"
        @click="ModuleBoxTab(index)"
        @mouseenter.native="YiRuModule(index)"
        @mouseleave.native="YiChuModule()"
      >
        <div
          style="
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            left: 3vh;
          "
        >
          {{ ModuleBoxList.name }}
        </div>
      </div>
    </div>
  </div>
  <div class="User" @click="goLogin"></div>
  <!-- 右边详细信息 -->
  <div class="ContentBox" v-if="ContentBoxShow">
    <div class="ImgBox">
      <img
        v-if="GuZhengJingDianList.logoUrl"
        :src="GuZhengJingDianList.logoUrl"
        alt=""
        style="width: 100%; height: 100%; border-radius: 25px"
      />
    </div>
    <div class="ConTitle">
      <span style="z-index: 99"> {{ GuZhengJingDianList.scenicName }}</span>
      <div class="bg"></div>
      <img
        @click="goDetail"
        src="../../assets/HomePage/HomePageTwo/JRJQAN.png"
        style="height: 3vh; cursor: pointer; z-index: 99; padding-top: 1vh"
      />
    </div>
    <div class="summary">
      <marquee
        direction="up"
        behavior="scroll"
        scrollamount="1"
        style="height: 100%; line-height: 3.5 vh; text-indent: 2em"
      >
        {{ GuZhengJingDianList.summary }}
      </marquee>
    </div>
  </div>
  <!-- 左边全屏 -->
  <div class="FullScreen" @click="FullScreen">
    <img
      :src="!fullscreen ? QuanPing : OutQuanPing"
      style="width: 100%; height: 100%"
    />
  </div>
  <div class="Tips" v-if="showTips" @click="closeTips">
    <img src="../../assets/HomePage/HomePageTwo/tipsJT.png" />
    <div class="TipsText">
      <div>欢迎来到湘江古镇虚拟旅游</div>
      <div>全屏游玩体验更好哦~</div>
      <div @click="FullScreen">立即全屏</div>
    </div>
  </div>
  <TiShiBox ref="childRef"></TiShiBox>
  <!-- 景区空间 -->
  <div class="jqkj-out-box" v-if="jqkjShow" @click="jqkjShow = false">
    <div class="jqkj-box">
      <div class="jqkj-box-title">景区空间</div>
      <div
        class="jqkj-box-item"
        v-for="(item, index) in jqkjList"
        :key="index"
        @click="jqkjDetail(item)"
      >
        <img
          :src="item.logoUrl"
          style="width: 100%; height: 180px; border-radius: 20px"
        />
        <div class="scenicName">{{ item.scenicName }}</div>
      </div>
    </div>
  </div>
</template>
  
<script>
import $ from "jquery";
import { ElLoading } from "element-plus";
import TiShiBox from "../Public/TiShiBox.vue";

export default {
  name: "Bmap",
  components: { TiShiBox },
  data() {
    return {
      QuanPing: require("../../assets/HomePage/HomePageTwo/QuanPing.png"),
      OutQuanPing: require("../../assets/HomePage/HomePageTwo/OutQuanPing.png"),
      showTips: true,
      DTmarker: [],
      ModuleBoxactive: -1,
      active: 999,
      // 控制地图显示高度
      zoom: 9,
      timer: [],

      ContentBoxShow: false,
      // 古镇信息
      GuZhengList: [],
      // 古镇景区信息
      GuZhengJingDianList: {
        scenicName: "暂无",
        logoUrl: "",
        summary: "暂无",
      },
      JWD: {
        longitude: [],
        latitude: [],
      },
      // 查看经纬度
      CKJWD: {
        lat: "",
        lng: "",
      },

      DiTu: "",
      fullscreen: false,
      ButtomNav: ["景区空间", "特色景点", "畅玩古镇"],
      ModuleBoxList: [
        {
          img: require("../../assets/HomePage/HomePageTwo/WuGuangOne.png"),
          name: "数字文创馆",
        },
        {
          img: require("../../assets/HomePage/HomePageTwo/WuGuangTwo.png"),
          name: "数字博物馆",
        },
        {
          img: require("../../assets/HomePage/HomePageTwo/WuGuangThere.png"),
          name: "非遗展览馆",
        },
        {
          img: require("../../assets/HomePage/HomePageTwo/WuGuangFour.png"),
          name: "建筑景观馆",
        },
      ],
      jqkjShow: false,
      jqkjList: [],
    };
  },
  mounted() {
    const loading = ElLoading.service({
      lock: true,
      text: "加载中...",
      background: "rgba(0, 0, 0, 0.8)",
    });
    setTimeout(() => {
      loading.close();
    }, 1500);
    // 获取古镇数据
    this.GetGuZhengList();

    let t = this;
    window.onresize = function () {
      t.fullscreen = t.getFullScreen();
    };
    let tips = sessionStorage.getItem("tips");
    // session中有值就不显示提示 没值就显示
    if (tips) {
      this.showTips = false;
    } else {
      this.showTips = true;
    }
  },
  methods: {
    jqkjDetail(data) {
      this.GuZhengJingDianList = data;
      this.goDetail();
    },
    // 进入景区
    goDetail() {
      if (this.GuZhengJingDianList.scenicName == "场坪村") {
        window.open("https://www.720yun.com/vr/f6fjOswyru2", "_blank");
      } else if (this.GuZhengJingDianList.scenicName == "中溪村") {
        window.open("https://www.720yun.com/vr/c3c23qfdali", "_blank");
      } else if (
        this.GuZhengJingDianList.scenicName == "乔口" ||
        this.GuZhengJingDianList.scenicName == "靖港" ||
        this.GuZhengJingDianList.scenicName == "铜官" ||
        this.GuZhengJingDianList.scenicName == "书堂山" ||
        this.GuZhengJingDianList.scenicName == "新康"
      ) {
        this.$router.push({
          name: "scenicspace",
          query: {
            scenicId: this.GuZhengJingDianList.scenicId,
          },
        });
      } else {
        this.$refs.childRef.ChuFa();
      }
    },
    // 关闭提示
    closeTips() {
      this.showTips = false;
      sessionStorage.setItem("tips", "close");
    },
    // 移入移出模块
    YiRuModule(index) {
      this.ModuleBoxactive = index;
      if (index == 0) {
        this.ModuleBoxList[
          index
        ].img = require("../../assets/HomePage/HomePageTwo/TabWuGuangOne.png");
      }
      if (index == 1) {
        this.ModuleBoxList[
          index
        ].img = require("../../assets/HomePage/HomePageTwo/TabWuGuangTwo.png");
      }
      if (index == 2) {
        this.ModuleBoxList[
          index
        ].img = require("../../assets/HomePage/HomePageTwo/TabWuGuangThere.png");
      }
      if (index == 3) {
        this.ModuleBoxList[
          index
        ].img = require("../../assets/HomePage/HomePageTwo/TabWuGuangFour.png");
      }
    },
    YiChuModule(index) {
      this.ModuleBoxactive = -1;
      this.ModuleBoxList[0].img = require("../../assets/HomePage/HomePageTwo/WuGuangOne.png");
      this.ModuleBoxList[1].img = require("../../assets/HomePage/HomePageTwo/WuGuangTwo.png");
      this.ModuleBoxList[2].img = require("../../assets/HomePage/HomePageTwo/WuGuangThere.png");
      this.ModuleBoxList[3].img = require("../../assets/HomePage/HomePageTwo/WuGuangFour.png");
    },
    // 获取首页古镇信息 & 初始化地图
    async GetGuZhengList() {
      let _self = this;
      var axios = require("axios");
      var config = {
        method: "get",
        url: this.$Schttp + "/vtp/app/scenic/scenicIndex",
        headers: {},
        data: {},
      };
      await axios(config)
        .then((res) => {
          this.GuZhengList = res.data.data;
          //  创建地图
          var map = new window.BMapGL.Map("container", {
            minZoom: 8,
            maxZoom: 15,
            zoom: 12,
          });

          for (var i = 0; i < this.GuZhengList.length; i++) {
            this.JWD.longitude.push(this.GuZhengList[i].longitude);
            this.JWD.latitude.push(this.GuZhengList[i].latitude);
          }
          for (var i = 0; i < this.GuZhengList.length; i++) {
            //   地图坐标
            var point = new window.BMapGL.Point(
              this.GuZhengList[i].longitude,
              this.GuZhengList[i].latitude
            );
            //  设置地标
            var Label = new window.BMapGL.Label(
              this.GuZhengList[i].scenicName,
              {
                position: point, // 设置标注的地理位置
                offset: new window.BMapGL.Size(0, 5), // 设置标注的偏移量
              }
            );
            map.addOverlay(Label); // 将标注添加到地图中

            //   字体样式
            Label.setStyle({
              color: "#01BDF4",
              backgroundColor: "rgb(0, 0, 0, 0)",
              borderRadius: "10px",
              padding: "0 10px",
              fontSize: "15px",
              lineHeight: "15px",
              border: "0",
              transform: "translateX(-50%)",
            });
            // 古镇地图标点
            var carIcon = new window.BMapGL.Icon(
              require("@/assets/HomePage/HomePageTwo/DiBiao.png"),
              new window.BMapGL.Size(30, 30),
              {
                imageSize: new window.BMapGL.Size(30, 30),
                imageOffset: new window.BMapGL.Size(0, 0),
              }
            );
            if (
              this.GuZhengList[i].scenicName == "乔口" ||
              this.GuZhengList[i].scenicName == "靖港" ||
              this.GuZhengList[i].scenicName == "铜官" ||
              this.GuZhengList[i].scenicName == "书堂山" ||
              this.GuZhengList[i].scenicName == "新康"
            ) {
              this.jqkjList.push(this.GuZhengList[i]);
              carIcon = new window.BMapGL.Icon(
                require("@/assets/HomePage/HomePageTwo/TouMing.png"),
                new window.BMapGL.Size(60, 60),
                {
                  imageSize: new window.BMapGL.Size(60, 60),
                  imageOffset: new window.BMapGL.Size(0, 0),
                }
              );
            }

            var marker = new window.BMapGL.Marker(point, {
              icon: carIcon,
              offset: new window.BMapGL.Size(0, -15),
            }); // 创建标注
            this.DTmarker.push(marker);
            map.addOverlay(marker); // 将标注添加到地图中
            // 现在为这个marker添加多个事件监听器 click设置点击事件 2024/5/30 阮祚银修改
            marker.addEventListener("click", (e) => {
              if (this.GuZhengJingDianList.scenicName == "场坪村") {
                window.open("https://www.720yun.com/vr/f6fjOswyru2", "_blank");
              } else if (this.GuZhengJingDianList.scenicName == "中溪村") {
                window.open("https://www.720yun.com/vr/c3c23qfdali", "_blank");
              } else if (
                this.GuZhengJingDianList.scenicName == "乔口" ||
                this.GuZhengJingDianList.scenicName == "靖港" ||
                this.GuZhengJingDianList.scenicName == "铜官" ||
                this.GuZhengJingDianList.scenicName == "书堂山" ||
                this.GuZhengJingDianList.scenicName == "新康"
              ) {
                this.$router.push({
                  name: "scenicspace",
                  query: {
                    scenicId: this.GuZhengJingDianList.scenicId,
                  },
                });
              } else {
                this.$refs.childRef.ChuFa();
              }
            });
            // 现在为这个marker添加多个事件监听器 mouseover设置鼠标移入事件
            marker.addEventListener("mouseover", (e) => {
              // 四舍五入保留六位小数
              var lng = e.currentTarget.latLng.lng.toFixed(6);
              var lat = e.currentTarget.latLng.lat.toFixed(6);

              for (var i = 0; 0 < this.JWD.longitude.length; i++) {
                if (
                  this.JWD.longitude[i] == lng &&
                  this.JWD.latitude[i] == lat
                ) {
                  // var opts = {
                  //     width: 480, // 信息窗口宽度
                  //     height: 260, // 信息窗口高度
                  //     title: '', // 信息窗口标题
                  //     message: '古镇介绍',
                  // }
                  // var content =
                  //     '<div style="width:80%;height:90%;display:flex; align-items:flex-end;"><div style="width:35%;height:70%;background-image:url(http://travel.lzxx8848.com/image/assets/HomePage/MapTan.png);background-size:100% 100%;"></div><div style="width:65%;height:68%;display:flex;align-self: center"><div style="writing-mode: vertical-lr;font-family: YouShe;background:rgba(0,0,0,0.5);text-align:center">' +
                  //     this.GuZhengList[i].scenicName +
                  //     '</div><div style="font-size:12px;background:rgb(253,189,0,0.2);padding:0.2rem";><span class="omit">' +
                  //     this.GuZhengList[i].summary +
                  //     '</span><div style="text-align:right;color:#fdbd00"><span class="JingQu" style="text-decoration:underline;cursor:pointer">进入景区</span></div></div></div></div>'
                  // var infoWindow =
                  //     new window.BMapGL.InfoWindow(
                  //         content,
                  //         opts
                  //     ) // 创建信息窗口对象
                  // e.domEvent.cancelBubble = true //点击标注时显示当前位置信息  阻止地图的点击事件
                  // 景点信息
                  this.GuZhengJingDianList.scenicName =
                    this.GuZhengList[i].scenicName;
                  this.GuZhengJingDianList.logoUrl =
                    this.GuZhengList[i].logoUrl;
                  this.GuZhengJingDianList.summary =
                    this.GuZhengList[i].summary;
                  this.GuZhengJingDianList.scenicId =
                    this.GuZhengList[i].scenicId;
                  this.CKJWD.lat = e.currentTarget.latLng.lat - 0.06;
                  this.CKJWD.lng = e.currentTarget.latLng.lng + 0.1;
                  // map.openInfoWindow(infoWindow, this.CKJWD) //开启信息窗口
                  this.ContentBoxShow = true;
                  // infoWindow.addEventListener('close', () => {
                  //     this.ContentBoxShow = false

                  // })
                  // if (!infoWindow.isOpen()) {
                  //     infoWindow.addEventListener(
                  //         'open',
                  //         () => {
                  //             $('.JingQu').click(() => {
                  //                 this.$router.push({
                  //                     name: 'scenicspace',
                  //                     query: {
                  //                         scenicId: this.GuZhengList[i].scenicId,
                  //                     },
                  //                 })
                  //             })
                  //         }
                  //     )
                  // } else {
                  //     var btn = $('.JingQu')
                  //     btn.onclick = function () {
                  //         this.$router.push({
                  //             name: 'scenicspace',
                  //             query: {
                  //                 scenicId:
                  //                     this.GuZhengList[i]
                  //                         .scenicId,
                  //             },
                  //         })
                  //     }
                  // }
                  // $('.JingQu').click(() => {
                  //     this.$router.push({
                  //         name: 'scenicspace',
                  //         query: {
                  //             scenicId:
                  //                 this.GuZhengList[i]
                  //                     .scenicId,
                  //         },
                  //     })
                  // })
                  return;
                }
              }
            });
          }

          // 设置标点连线
          // 创建一个空的折线对象
          var polyline = new window.BMapGL.Polyline(
            [
              new window.BMapGL.Point(112.742029, 28.510482),
              new window.BMapGL.Point(112.795734, 28.462833),
              new window.BMapGL.Point(112.810734, 28.415833),
              new window.BMapGL.Point(112.844734, 28.416833),
              new window.BMapGL.Point(112.880734, 28.408833),
            ],
            // 折线的样式
            {
              strokeColor: "#E8ED75",
              strokeWeight: 3,
              strokeOpacity: 0.8,
            }
          );
          // 设置为虚线
          polyline.setStrokeStyle("dashed");
          // 把折线添加到地图上
          map.addOverlay(polyline);

          //  地图默认缩放比例
          map.centerAndZoom(point, 12);
          //  是否开启滚轮
          map.enableScrollWheelZoom(true);
          //  启用键盘操作
          map.enableKeyboard(true);
          //  个性化地图
          map.setMapStyleV2({
            styleId: "91197c8f5514e806d4bc211d996d8108",
          });
          // 删除放大缩小控件
          // map.removeControl(map.getZoomControl());
          var JGpoint = new BMapGL.Point(112.795734, 28.462833);
          map.setCenter(JGpoint); // 设置地图中心点
          var scaleCtrl = new window.BMapGL.ScaleControl();
          map.addControl(scaleCtrl); // 添加比例尺控件
          var zoomCtrl = new window.BMapGL.ZoomControl();
          map.addControl(zoomCtrl); // 添加缩放控件
        })
        .catch(function (error) {})
        .finally(function () {
          _self.TeJjiao();
        });
    },
    // 提交访问记录
    TeJjiao() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: this.$Schttp + "/cms/app/access/vtSave",
        headers: {
          Authorization: "Bearer " + this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then((res) => {
          let JGZG = require("../../assets/HomePage/HomePageTwo/DiBIaoJG.png");
          let QKGZ = require("../../assets/HomePage/HomePageTwo/DiBIaoQK.png");
          let TGGZ = require("../../assets/HomePage/HomePageTwo/DiBIaoTG.png");
          let XKGZ = require("../../assets/HomePage/HomePageTwo/DiBIaoXK.png");
          let STSGZ = require("../../assets/HomePage/HomePageTwo/DiBIaoSTS.png");
          for (let i = 0; i < this.GuZhengList.length; i++) {
            let url = "";
            if (this.GuZhengList[i].scenicName == "乔口") url = QKGZ;
            if (this.GuZhengList[i].scenicName == "靖港") url = JGZG;
            if (this.GuZhengList[i].scenicName == "铜官") url = TGGZ;
            if (this.GuZhengList[i].scenicName == "新康") url = XKGZ;
            if (this.GuZhengList[i].scenicName == "书堂山") url = STSGZ;
            if (url) {
              $(".BMap_Marker")[i].style.backgroundImage = "url(" + url + ")";
              $(".BMap_Marker")[i].style.backgroundSize = "100% 100%";
              this.timer[i] = setInterval(() => {
                $(".BMap_Marker")[i].style.transform = "scale(1)";
                $(".BMap_Marker")[i].style.transition = "3s";
                setTimeout(() => {
                  $(".BMap_Marker")[i].style.transform = "scale(0.5)";
                }, 2000);
              }, 3000);
            }
            // $('.BMap_Marker')[i].style.backgroundImage = 'url(' + imgList[i] + ')'
            // $('.BMap_Marker')[i].style.backgroundSize = "100% 100%"
            // this.timer[i] = setInterval(() => {
            //     $('.BMap_Marker')[i].style.transform = 'scale(1)'
            //     $('.BMap_Marker')[i].style.transition = '3s'
            //     setTimeout(() => {
            //         $('.BMap_Marker')[i].style.transform = 'scale(0.5)'
            //     }, 2000)
            // }, 3000)
          }
        })
        .catch(function (error) {});
    },
    // 底部导航点击跳转
    ButtomNavTab(index) {
      // 跳转数字文创馆
      if (index == 0) this.jqkjShow = true;
      // 跳转数字博物馆
      if (index == 1) this.$router.push("/SpecialScenic");
      // 跳转非遗展览馆
      if (index == 2) this.$router.push("/GameList");
      // 跳转建筑景观馆
      // if (index == 3) this.$router.push('/ConstructionLandscape')
      // // 跳转特色景点
      // if (index == 4) this.$router.push('/SpecialScenic')
      // //跳转畅玩古镇
      // if (index == 5) this.$router.push('/GameList')
      // // 跳转随心购物
      // if (index == 6) this.$router.push('/ShopAtWillHome')
      // // 跳转倾斜摄影
      // if (index == 7) this.$router.push('/ObliquePhoto')
    },
    ModuleBoxTab(index) {
      if (index == 0) this.$router.push("/CulturalHomePage");
      if (index == 1) this.$router.push("/MuseumHome");
      if (index == 2) this.$router.push("/IntangibleCultural");
      if (index == 3) this.$router.push("/ConstructionLandscape");
    },
    YiRuBottomNav(index) {
      this.active = index;
    },
    YiChuBottomNav() {
      this.active = 999;
    },
    // 去登陆
    goLogin() {
      if (this.$cookies.get("token") == null) {
        this.$router.push("/login");
      } else {
        this.$router.push("/personalCenter");
      }
    },
    // 全屏
    FullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    // 判断是否全屏
    getFullScreen() {
      /*两个细节：使用document判断  能力测试*/
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeUnmount() {
    // 释放地图实例所占用的内存
    this.DiTu = null;
    // 清除定时器
    for (let i = 0; i <= this.timer.length; i++) {
      clearInterval(this.timer[i]);
    }

    this.timer = [];
  },
};
</script>
  
  <style lang="less" scoped>
@import "../../assets/unify.less";

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@font-face {
  font-family: "YouShe";
  src: url("../../ZiTi/YouShe.ttf");
}
.bmap {
  width: 100%;
  height: 100%;
}
/deep/ .BMap_stdMpZoom {
  display: none;
}

.BianKuanClass {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-image: url("../../assets/HomePage/HomePageTwo/HomepageBG.png");
  background-size: 100% 100%;
  pointer-events: none;
  .TopTitle {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(215, 238, 253);
    // font-weight: 600;
    font-size: 5vh;
    font-family: "YouShe";
    letter-spacing: 0.4rem;
  }
}
// .BottomNavBox {
//     width: 68%;
//     height: 100px;
//     position: fixed;
//     bottom: 50px;
//     left: 15%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     font-size: italic;
//     font-size: 2vh;
//     opacity: 1;
//     z-index: 999;

//     .ModuleNavBox {
//         height: 80%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         color: rgb(248, 248, 248);

//         cursor: pointer;
//         font-family: 'YouShe';
//         font-size: 2.5vh;
//         white-space: nowrap;
//         div {
//             background-image: url(../../assets/HomePage/HomePageTwo/titleBg.png);
//             background-size: 100% 100%;
//             background-repeat: no-repeat;
//             height: 8vh;
//             width: 28vh;
//             text-align: center;
//             line-height: 8vh;
//             font-size: @First_Title_Size;
//         }
//         img {
//             display: inline-block;
//             box-shadow: 0 17px 7px 0 rgba(24, 37, 56, 0.8);
//         }
//     }
// }
.BottomNavBox {
  width: 55%;
  height: 100px;
  position: fixed;
  bottom: 50px;
  left: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: italic;
  font-size: 2vh;
  opacity: 1;
  z-index: 999;
  .actived {
    padding: 0 65px !important;
    height: 100% !important;
    background-image: url(../../assets/HomePage/HomePageTwo/ModuleNavBGTwo.png) !important;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 242, 96);
    cursor: pointer;
    // transform: scale(1.3);
    div {
      position: relative;
      top: -1vh;
    }
  }
  .ModuleNavBox {
    padding: 0 55px;
    height: 55%;
    background-image: url(../../assets/HomePage/HomePageTwo/ModuleNavBG.png);
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(248, 248, 248);
    cursor: pointer;
    font-family: "YouShe";
    font-size: 2.5vh;
  }
}
.ModuleBox {
  width: 20%;
  height: 65%;
  position: absolute;
  left: 15px;
  top: 12%;
  // background-image: url(../../assets/HomePage/HomePageTwo/ModuleBoxBG.png);
  // background-size: 100% 100%;
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 3vh;
  letter-spacing: 1vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  .Module {
    width: 90%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    .ModuleLiTwo {
      padding: 0.5vh 0;
      height: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      // background-image: url(../../assets/HomePage/HomePageTwo/ModuleListBG.png);
      background-size: 100% 100%;
      font-family: "YouShe";
      img {
        width: 17%;
        height: 70%;
        margin: 0 2.5vh;
      }
    }
    .ModuleBoxactiveactived {
      background-size: 100% 100%;
      font-size: 2.3vh;
      font-weight: 600;
    }
  }
}
.FullScreen {
  position: absolute;
  top: 3vh;
  left: 10%;
  color: #fff;
  // background: #fff;
  z-index: 9999;
  cursor: pointer;

  width: 7.5vh;
  height: 7vh;
  // border-radius: 50%;
  // background-color: #182d46;
  display: flex;
  justify-content: center;
  align-items: center;
}
.User {
  width: 7.5vh;
  height: 7vh;
  background: url(../../assets/ConstructionLandscape/GeRen.png) no-repeat;
  background-size: 100% 100%;
  align-self: end;
  flex-shrink: 0;
  cursor: pointer;
  margin-right: 1rem;
  position: absolute;
  top: 3vh;
  right: 10%;
  z-index: 999;
}
.ContentBox {
  width: 18%;
  height: 70%;
  position: absolute;
  right: 5%;
  top: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(203, 251, 255);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  overflow: hidden;
  z-index: 9998;
  border-radius: 25px;

  .ImgBox {
    flex-shrink: 0;
    background-size: 100% 100%;
    height: 36%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
  }
  .ConTitle {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-family: "YouShe";
    position: relative;
    font-size: @First_Title_Size;
    display: flex;
    justify-content: space-between;
    padding: 1vh 2vh 0;
    align-items: center;
    .bg {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      height: 8vh;
      background: url(../../assets/HomePage/HomePageTwo/JQTitleBG.png) no-repeat;
      background-size: 90% 100%;
      background-position: left;
      animation: mymove 2s infinite;
      z-index: 1;
    }

    @keyframes mymove {
      0% {
        transform: translate3d(-50%, 0, 0);
      }
      // 50% {
      //     transform: translate3d(0, 0, 0);
      // }
      100% {
        transform: translate3d(100%, 0, 0);
      }
    }
  }
  .summary {
    height: 100%;
    width: 85%;

    overflow: scroll;
    font-size: 1.7vh;
    margin-top: 1rem;
    padding: 1rem 0.5rem;
  }
}

.Tips {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
  img {
    height: 36%;
    width: 18%;
    position: absolute;
    left: 12%;
    top: 12%;
  }
  .TipsText {
    height: 32vh;
    font-size: @Second_Title_Size;
    padding: 6vh 8vh;
    box-sizing: border-box;
    line-height: 5vh;
    color: #fff;
    text-align: center;
    position: relative;
    border: 1px solid #fff;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(1.2px);
    div {
      margin: 1vh 0;
    }
    div:nth-child(1) {
      font-size: 5vh;
    }
    div:nth-child(2) {
      font-size: 3.2vh;
    }
    div:nth-child(3) {
      border: 2px solid #fff;
      display: inline-block;
      border-radius: 25px;
      padding: 0.5vh 3vh;
      cursor: pointer;
      margin-top: 2vh;
    }
  }
}
::v-deep .BMap_bubble_pop {
  padding: 0 !important;
  background: none !important;
  border: none !important;
  // background-image: url(../../assets/HomePage/HomePageTwo/JingQuBG.png) !important;
  // background-size: 100% 100% !important;
  img {
    display: none;
  }
}
::v-deep .BMap_bubble_title {
  display: none;
}
::v-deep .BMap_bubble_top {
  display: none;
}
::v-deep .BMap_bubble_content {
  width: 90% !important;
  height: 80% !important;
  top: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  color: white !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 7 !important;
  -webkit-box-orient: vertical !important;
}
::v-deep .BMap_bubble_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .anchorBL {
  display: none;
}
::v-deep .shadow {
  display: none;
}

::v-deep .omit {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  word-break: break-all;
}
// 滚动条设置
@remvw: 1920 /100vw !important;

::-webkit-scrollbar {
  width: 7 / @remvw !important;
  /*对垂直流动条有效*/
  height: 7 / @remvw !important;
  /*对水平流动条有效*/
}

/*定义外层滚动槽*/
::-webkit-scrollbar-track-piece {
  border-radius: 6 / @remvw !important;
}

/*定义内层 滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 6 / @remvw !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8b8b8b !important;
  opacity: 0.3 !important;
}

/*定义两端按钮的样式*/
::-webkit-scrollbar-button {
  display: none !important;
}

/*定义边角样式*/
::-webkit-scrollbar-corner {
  display: none !important;
}

.jqkj-out-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
  z-index: 9999;
  .jqkj-box {
    background-image: url("../../assets/HomePage/HomePageTwo/JQKJ_BG.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: rgba(0, 0, 0, 0.7);
    width: 60vw;
    height: 60vh;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px 20px;
    box-sizing: border-box;
    .jqkj-box-title {
      width: 100%;
      font-size: 26px;
      box-sizing: border-box;
      padding-left: 20px;
      color: #fff;
      font-family: "YouShe";
    }
    .jqkj-box-item {
      color: #fff;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      width: 18%;
      .scenicName {
        width: 80%;
        background: url("../../assets/HomePage/HomePageTwo/JQKJ_BTN.png")
          no-repeat;
        background-size: 100% 100%;
        margin-top: 20px;
      }
    }
  }
}
</style>